<script lang="ts" setup>
import { vIntersectionObserver } from "@vueuse/components";
import { useContentStore } from "~/store/content";
import VDisclaimer from "~/components/VDisclaimer.vue";

const contentStore = useContentStore();
// Analytics
const { send } = useAnalytics();
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("MainPage:Promo:NarrowAd:View", {
      title: contentStore.mainPage?.ticker.title || "",
      dest: contentStore.mainPage?.ticker.offerUrl,
    });
  }
};
const onClick = () => {
  send("MainPage:Promo:NarrowAd:Click", {
    title: contentStore.mainPage?.ticker.title || "",
    dest: contentStore.mainPage?.ticker.offerUrl,
  });
};
</script>

<template>
  <div
    v-if="contentStore.mainPage?.ticker"
    v-intersection-observer="onVisibility"
    class="ticker-wrapper"
    @click="onClick"
  >
    <NuxtLink
      :to="contentStore.mainPage?.ticker.offerUrl"
      class="ticker"
      :style="{
        backgroundImage: `url(${contentStore.mainPage?.ticker.publicImageUrl})`,
      }"
    />
    <VDisclaimer :content="contentStore.mainPage?.ticker.disclaimer" />
  </div>
</template>

<style lang="postcss">
.ticker-wrapper {
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  margin: 64px 0 54px;
  position: relative;

  & .disclaimer {
    bottom: var(--pl-unit-x1);
    right: var(--pl-unit-x5);
  }
}

.ticker {
  display: block;
  height: 60px;
  width: 9090px;
  background-repeat: repeat-x;
  background-size: contain;
  animation: slide 75s linear infinite;
}

@media (--pl-viewport-from-ml) {
  .ticker-wrapper {
    margin-bottom: 70px;
  }
}

@media (--pl-viewport-from-l) {
  .ticker-wrapper {
    margin-top: 72px;
    margin-bottom: 82px;
  }

  .ticker {
    height: 90px;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-4545px);
  }
}
</style>
