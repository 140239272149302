<script setup lang="ts">
import { computed } from "vue";
import { useQRCode } from "@vueuse/integrations/useQRCode";
import VLoader from "@magnit/core/src/components/VLoader/VLoader.vue";

interface IQrCodeProps {
  qrcode: string;
  size?: number;
  blur?: number;
  loaderSize?: "l";
  loaderColor?: "primary" | "invert" | "inherit";
}

const props = withDefaults(defineProps<IQrCodeProps>(), {
  size: 92,
  blur: 8,
  loaderSize: "l",
  loaderColor: "primary",
});

const qrcode = computed(() => {
  const qrcode = props.qrcode;
  return useQRCode(qrcode, {
    margin: 0,
    width: props.size,
  });
});
</script>

<template>
  <div
    class="qr-code"
    :style="{
      'max-width': `${props.size}px`,
      height: qrcode.value ? 'auto' : `${props.size}px`,
    }"
  >
    <img
      v-if="qrcode.value"
      class="qr-code__img"
      :class="{
        'qr-code_blur': !props.qrcode,
      }"
      :style="
        !props.qrcode
          ? {
              filter: `blur(${props.blur}px)`,
            }
          : {}
      "
      :src="qrcode.value"
      alt="QR Code"
    />
    <VLoader v-else :color="props.loaderColor" :size="props.loaderSize" />
  </div>
</template>

<style lang="postcss">
.qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  &_blur {
    opacity: 0.25;
  }

  &__img {
    max-width: 100%;
  }
}
</style>
