import { ref, watch, computed } from "vue";
import type { IProduct } from "~/components/VProduct/VProduct.types";
import type { ICategorySectionResponse } from "~/store/content";
import { ProductCatalogType, useContentStore } from "~/store/content";
import { formatPromoProduct, usePromoStore } from "~/store/promo";
import { formatGoodsProduct, useGoodsStore } from "~/store/goods";
import useProductsListWithYaSlot from "~/composables/useProductsListWithYaSlot";
import { Routes } from "~/utils/routes";
import { useCategoriesStore } from "~/store/categories";
import { useStoresStore } from "~/store/stores";

export interface IProductWithUrl extends IProduct {
  url: string;
}

const useMainPageCategoryProducts = (
  categorySection: ICategorySectionResponse,
) => {
  const contentStore = useContentStore();
  const promoStore = usePromoStore();
  const goodsStore = useGoodsStore();
  const categoriesStore = useCategoriesStore();
  const storesStore = useStoresStore();
  const products = ref<IProductWithUrl[]>([]);
  async function requestProducts() {
    if (contentStore.status.mainPage !== "success") {
      return;
    }

    if (categorySection.categoryType === ProductCatalogType.Promo) {
      const { data } = await promoStore.requestProducts({
        categoryId: categorySection.categoryId,
        limit: 8,
        offset: 0,
      });
      if (data.value) {
        products.value = data.value.data.map((item) => ({
          ...formatPromoProduct(item),
          url: promoStore.makePromoProductUrl(item),
        }));
      }
    } else {
      const { data } = await goodsStore.requestProducts({
        categoryIDs: categorySection.categoryId
          ? [categorySection.categoryId]
          : undefined,
        pagination: {
          number: 1,
          size: 8,
        },
      });
      if (data.value) {
        products.value = data.value.goods.map((item) => ({
          ...formatGoodsProduct(item),
          url: goodsStore.getLinkGoodsProduct(item, categorySection.categoryId),
        }));
      }
    }
  }

  watch(
    () => contentStore.status.mainPage,
    async (next) => {
      if (next === "success") {
        await requestProducts();
      }
    },
    {
      immediate: true,
    },
  );

  const productsWithYaSlot = categorySection.adfoxPayload
    ? useProductsListWithYaSlot(
        computed(() => products.value),
        categorySection.adfoxPayload[0],
        APP_MAIN_PAGE_CATEGORY_BANNER_INDEX,
        undefined,
        true,
      )
    : computed(() => products.value);

  const onStabAdfox = (index: number) => {
    products.value?.splice(index, 1);
  };

  const link = computed(() => {
    if (!categorySection) {
      return "";
    }

    const category = categorySection.categoryId
      ? categoriesStore.getCategoryById(
          categorySection.categoryId.toString(),
          categorySection.categoryType === ProductCatalogType.Promo
            ? "promo"
            : "goods",
        )
      : null;

    let url = `${
      categorySection.categoryType === ProductCatalogType.Promo
        ? Routes.PromoCatalog
        : Routes.Catalog
    }`;
    if (category) {
      url += `/${categorySection.categoryId}-${category.code}`;
    }
    url += `?shopCode=${storesStore.currentStore.code}`;
    return url;
  });

  return {
    link,
    productsWithYaSlot,
    onStabAdfox,
  };
};

export default useMainPageCategoryProducts;
