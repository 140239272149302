import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";

const yaSlotConfig: IYaSlotConfig = {
  id: "adfox_169286179119686858",
  p1: "cwbaa",
  p2: "htnq",
  pfc: "fgigo",
};

export default yaSlotConfig;
