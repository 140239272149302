<script setup lang="ts">
import VYaSlot from "@magnit/core/src/containers/VYaSlot/VYaSlot.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import yaSlotConfig from "~/ya-slots/useful";

const slotApi = useSlotsStore();
const { adfoxOwnerId } = usePublicConfig();
const { send } = useAnalytics();
const stub = ref(false);
</script>

<template>
  <VWrapperLocal v-if="!stub">
    <div class="useful__yaslot">
      <VYaSlot
        :api-ready="slotApi.slotStatus === IYaSlotApiStatus.SUCCESS"
        :api-error="slotApi.slotStatus === IYaSlotApiStatus.ERROR"
        :owner-id="adfoxOwnerId"
        :config="yaSlotConfig"
        :analytics-sender="send"
        :analytics-view="{
          name: 'MainPage:AdFox:PWA:View',
        }"
        :analytics-click="{
          name: 'MainPage:AdFox:PWA:Click',
        }"
        @stub="stub = true"
      />
    </div>
  </VWrapperLocal>
</template>

<style scoped lang="postcss">
.useful__yaslot {
  display: flex;
  margin-top: 48px;

  &:deep(a) {
    border-radius: 16px;
    overflow: hidden;

    img {
      height: 88px !important;
      object-fit: cover;
    }
  }

  & .disclaimer {
    bottom: var(--pl-unit-x2);
    right: var(--pl-unit-x3);
  }
}

@media (--pl-viewport-from-m) {
  .useful__yaslot {
    &:deep(a) {
      border-radius: 20px;

      img {
        height: 104px !important;
      }
    }

    & .disclaimer {
      right: var(--pl-unit-x5);
    }
  }
}

@media (--pl-viewport-from-ml) {
  .useful__yaslot {
    margin-top: 64px;
  }
}

@media (--pl-viewport-from-l) {
  .useful__yaslot {
    margin-top: 72px;

    &:deep(a) {
      border-radius: 24px;
    }
  }
}

@media (--pl-viewport-from-xl) {
  .useful__yaslot {
    & .disclaimer {
      right: var(--pl-unit-x6);
    }
  }
}
</style>
